
import { defineComponent, onMounted, reactive, toRefs } from "vue";
import UserResource from "@/resources/UserResource";
import Pagination from "@/components/Pagination/Pagination.vue";
import TableToolbar from "@/components/TableToolbar.vue";
import TableFilters from "@/components/TableFilters.vue";
import Filters from "@/components/User/Filters.vue";
import useList from "@/composables/use-list";

export default defineComponent({
  components: { Filters, TableFilters, TableToolbar, Pagination },

  setup() {
    const filters = reactive({
      name: undefined,
      email: undefined,
    });
    const { state, fetch, pagination, removeItem } = useList(UserResource);
    const list = toRefs(state);

    function submitFilters() {
      fetch({ page: 1, filters });
    }

    function clearFilters() {
      Object.assign(filters, {
        name: undefined,
        email: undefined,
      });

      fetch();
    }

    onMounted(() => {
      fetch({ expand: "role" });
    });

    return {
      filters,
      clearFilters,
      submitFilters,
      items: list.items,
      pagination,
      removeItem,
    };
  },
});
